var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{attrs:{"title":"新增考核计划"}},[_c('div',{staticStyle:{"padding-top":"16px"}}),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 4 },"wrapper-col":{ span: 16 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"计划名称"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
                      {
                rules: [{ required: true, message: '请输入' }],
              },
            ]),expression:"[\n              'name',\n                      {\n                rules: [{ required: true, message: '请输入' }],\n              },\n            ]"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"考核时间"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'time',
                                           {
           
                rules: [{ required: true, message: '请选择' }],
              },
                          ]),expression:"[\n                            'time',\n                                           {\n           \n                rules: [{ required: true, message: '请选择' }],\n              },\n                          ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":[],"format":"YYYY-MM-DD"}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"考核人员"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'users',
                                           {
           
                rules: [{ required: true, message: '请选择' }],
              },
                          ]),expression:"[\n                            'users',\n                                           {\n           \n                rules: [{ required: true, message: '请选择' }],\n              },\n                          ]"}],staticStyle:{"width":"100%"},attrs:{"mode":"multiple"}},_vm._l((_vm.userList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'remark',
              ,
            ]),expression:"[\n              'remark',\n              ,\n            ]"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.saveLoading}},[_vm._v("提交")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }