import request from '../request'

export function fetchList(data) {
    return request({
        url: '/model-analysis/assessPlan/list',
        method:"post",
        data
    })
}


export function edit(data) {
    return request({
        url: `/model-analysis/assessPlan/update`,
         method:"post",
        data
    })
}

export function add(data) {
    return request({
        url: `/model-analysis/assessPlan/add`,
         method:"post",
        data
    })
}

export function del(data) {
    return request({
        url: `/model-analysis/assessPlan/delete`,
         method:"post",
        data
    })
}
export function assess(data) {
    return request({
        url: `/model-analysis/assessPlan/appraise`,
         method:"post",
        data
    })
}
